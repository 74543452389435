import { useQuery } from '@tanstack/react-query';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { buildGqlQuery, gqlClient } from 'utilities/gql/gql.util';
import { queryKeys } from './keys';

export function useGetSelectedConsultancyId() {
    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();

    const selectedSchema = tenants && tenants.length === 1 ? tenants[0] : undefined;
    const enabled = selectedSchema !== undefined;

    return useQuery({
        enabled,
        queryFn: async () => {
            const gqlQuery = buildGqlQuery({
                query: () => {
                    return {
                        query: {
                            __name: 'getConsultancy',
                            consultancy: { __args: { where: { schema: { _eq: selectedSchema } } }, id: true }
                        }
                    };
                }
            });
            const client = gqlClient({
                accessToken: localStorage.accessToken,
                url: process.env.REACT_APP_GRAPHQL_URL
            });

            const res = await client.request(gqlQuery);

            if (res.consultancy.length !== 1) {
                throw new Error('Consultancy not found');
            }

            return res;
        },
        queryKey: queryKeys.consultancy.getConsultancy(selectedSchema),
        select: data => {
            return data.consultancy[0].id;
        }
    });
}

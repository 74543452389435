export const queryKeys = {
    actvities: {
        getActivities: ({ activityName, limit, offset, schemas = [] }) => [
            'activities',
            'getActivities',
            activityName,
            limit,
            offset,
            ...schemas
        ],
        getActivitiesAtGlance: ({
            activityDueDateFrom,
            activityDueDateTo,
            isAssignedToMe,
            isFlagged,
            schemas,
            status,
            userId
        }) => [
            'activities',
            'getActivititesAtGlance',
            ...schemas,
            isAssignedToMe,
            isFlagged,
            activityDueDateFrom,
            activityDueDateTo,
            status,
            userId
        ],
        getActivitiesCompleted: ({ activityName, limit, offset, schemas = [] }) => [
            'activities',
            'getActivities',
            activityName,
            limit,
            offset,
            ...schemas
        ],
        getActivitiesCompletedAtGlance: ({
            activityCompletedDateFrom,
            activityCompletedDateTo,
            isAssignedToMe,
            isFlagged,
            schemas,
            status,
            userId
        }) => [
            'activities',
            'getActivititesAtGlance',
            ...schemas,
            isAssignedToMe,
            isFlagged,
            activityCompletedDateFrom,
            activityCompletedDateTo,
            status,
            userId
        ],
        getActivitiesFilters: schemas => [
            'activities',
            'getActivitiesFilters',
            ...schemas
        ],
        getActivitiesPaginator: ({
            activityAssignees = [],
            activityDueDateFrom,
            activityDueDateTo,
            activityName,
            activityRecurrence = [],
            activityReviewers = [],
            activityStatus = [],
            activityTypes = [],
            entityName = [],
            isAssignedToMe,
            isFlagged,
            limit,
            offset,
            schemas,
            userId
        }) => [
            'activities',
            'getActivitiesPaginator',
            ...schemas,
            ...activityAssignees,
            ...activityReviewers,
            limit,
            offset,
            activityName,
            activityDueDateFrom,
            activityDueDateTo,
            ...activityTypes,
            entityName,
            ...activityStatus,
            ...activityRecurrence,
            isAssignedToMe,
            isFlagged,
            userId
        ]
    },
    consultancy: {
        getConsultancy: (schema) => ['consultancy', 'getConsultancy', schema]
    },
    departments: {
        getDepartments: (schema) => ['departments', 'getDepartments', schema]
    },
    positions: {
        getPositions: (schema) => ['positions', 'getPositions', schema]
    },
    users: {
        getCommentUserTagging: ({ excludeCertificationRole, schemas = [] }) => [
            'users',
            'getCommentUserTagging',
            excludeCertificationRole,
            ...schemas
        ]
    }
};
